<template>
<AuthLayout>
<template #content>
  
  <div class="SamplesPage">
    
    <!-- first -->
    <template
      v-if="active?.number === 1 && active?.isEmpty"
    >
      <div
        class="SamplesPage__title"
      ><!--
     -->Welcome, {{ patient.name }}!
      </div>
      <div class="SamplesPage__subtitle">
        Please select the first sample to start the breath test
      </div>
    </template>
    
    <!-- last -->
    <template
      v-if="allSamples?.every((i) => i.sample_added_at)"
    >
      <div
        class="SamplesPage__title"
      ><!--
     -->Well Done!
      </div>
      <div class="SamplesPage__subtitle">
        All samples done
      </div>
    </template>
    
    <!-- counter -->
    <template
      v-else-if="!activeTimer.isExpired.value && active?.number !== 1 && !loading"
    >
      <div
        class="SamplesPage__title"
      ><!--
     -->Well Done!
      </div>
      <div class="SamplesPage__subtitle">
        {{ `In ${active?.time - last?.time} minutes you will receive text or email reminder to collect the next sample` }}
      </div>
    </template>
    
    <!-- active -->
    <template
      v-else-if="activeTimer.isExpired.value"
    >
      <div
        class="SamplesPage__title"
      ><!--
     -->Welcome Back, {{ patient.name }}!
      </div>
      <div class="SamplesPage__subtitle">
        Please select the next sample
      </div>
      <div class="SamplesPage__instructions">
        <InfoCircleOutlined
          class="SamplesPage__instructions-icon"
        />
        Please, read the
        <span
          @click="$router.push({ name: 'patient.samples.baseline' })"
          class="SamplesPage__instructions-link"
        >
          basic instructions
        </span>
      </div>
    </template>
    
    <div
      class="SamplesPage__body"
    >
      <div
        v-if="allSamples"
        v-for="(sample) in allSamples" :key="sample.number"
        @click="sampleStatus(sample) !== 'blocked' ? toSample(sample) : false"
        :class="[
          `SamplesPage__sample`,
          `SamplesPage__sample-${sampleStatus(sample)}`
        ]"
      >
        <div class="SamplesPage__sample-left">
          <CheckCircleFilled
            class="SamplesPage__check-icon"
          />
          <div class="SamplesPage__sample-label">
            {{ `Sample ${sample.number}` }}
          </div>
        </div>
        <div class="SamplesPage__sample-right">
          <RightOutlined
            class="SamplesPage__arrow"
          />
        </div>
      </div>
      
      <div
        v-if="!loading"
        :class="[
          `SamplesPage__sample`,
          {'SamplesPage__sample-done': this.request?.status !== 'test_completed'},
          {'SamplesPage__sample-blocked': this.request?.status === 'test_completed'}
        ]"
        @click="this.request?.status !== 'test_completed' ? $router.push({ name: 'patient.samples.comment' }) : false"
      >
        <div class="SamplesPage__sample-left">
          <CheckCircleFilled
            class="SamplesPage__check-icon"
          />
          <div class="SamplesPage__sample-label">
            Comments
          </div>
        </div>
        <div class="SamplesPage__sample-right">
          <RightOutlined
            class="SamplesPage__arrow"
          />
        </div>
      </div>
      
      <a-button
        v-if="isFinist && request.status === 'draft'"
        class="ant-btn-huge SamplesPage__submit"
        type="green"
        size="large"
        @click="onSubmit"
      >
        Submit
      </a-button>
      <div class="switchButton" @click="switchToEmma">
        Switch to AI Testing Assistant
      </div>
    </div>
    
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import moment from 'moment'
import { isSuccess } from '@/helpers/http.helper'
import AuthLayout from "@/components/patient/auth.layout/AuthLayout"
import { CheckCircleFilled, RightOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
import { mapActions, mapGetters, mapMutations } from "vuex"
import { useTimer } from 'vue-timer-hook'
import { PatientRequestService } from "@/services/api_v1/patient"
import { toast } from "@/helpers/error.helper"
import _ from "lodash"

export default {
  name: "SamplesPage",
  components: {
    AuthLayout,
    InfoCircleOutlined,
    CheckCircleFilled,
    RightOutlined,
  },
  data() {
    return {
      ddd: moment(moment().format()).add(120, 'seconds').valueOf(), // 1670780627026
      now: moment().utc(),
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      isRequest: 'PatientRequestStore/getIsInit',
      request: 'PatientRequestStore/getInit',
      allSamples: 'PatientSampleStore/getAll',
      patient: 'PatientPatientStore/getPatient',
    }),
    isFinist() {
      return this.allSamples?.every((i) => i.sample_added_at)
    },
    active() {
      return this.allSamples?.find((i) => {
        const activeTime = moment.utc(this.request.test_started_at).add(i.time, 'minutes')
        return (i.isEmpty && this.now.diff(activeTime.utc()) < 0) || i.number === this.allSamples?.length
      })
    },
    last() {
      return this.allSamples?.find((i) => i.number === this.active?.number - 1)
    },
    activeTimer() {
      let result = moment().valueOf()
      if (this.allSamples && this.active) {
        const activeTime = moment.utc(this.request.test_started_at).add(this.active.time, 'minutes')
        if (moment().utc().diff(activeTime.utc()) > 0) {
          result = { isExpired: { value: true } }
        } else {
          result = activeTime.valueOf()
        }
      }
      return _.isNumber(result) ? useTimer(result) : result
    },
  },
  methods: {
    ...mapActions({
      sampleFetch: 'PatientSampleStore/fetch',
      fetchRequest: 'PatientRequestStore/fetchInit',
    }),
    ...mapMutations({
      setSampleInit: 'PatientSampleStore/setItemInit',
      setRequestField: 'PatientRequestStore/setItemInitField',
    }),
    sampleStatus(sample) {
      let status = null
      if (!sample.isEmpty && this.request?.status !== 'test_completed') status = 'done'
      else if (sample.isEmpty && sample.number === 1) status = 'active'
      else if (sample.isEmpty && sample.number < this.active?.number) status = 'active'
      else if (sample.isEmpty && this.active?.number === sample.number && this.activeTimer?.isExpired?.value) status = 'active'
      else status = 'blocked'
      return status
    },
    toSample(sample) {
      if (sample.number === 1 && sample.isEmpty) {
        this.$router.push({ name: 'patient.samples.baseline' })
      } else {
        this.$router.push({ name: 'patient.sample', params: { sample: sample.number } })
      }
    },
    async onSubmit() {
      await PatientRequestService.done()
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          this.setRequestField({ field: 'status', value: 'test_completed' })
          toast ({ type: 'success', msg: 'Success' })
          this.$router.push({ name: 'patient.samples.return' })
        })
        .catch((err) => {
          toast ({ msg: err?.data?.data?.message })
        })
        .finally(() => {
          this.loading = false
        })
    },
    switchToEmma() {
      localStorage.setItem('test_preference', 'patient.emma')
      this.$router.push({ name: 'patient.emma' });
    }
  },
  created() {
    let self = this
    setInterval(function () {
      self.now = moment().utc()
    }, 1000)
  },
  async mounted() {
    this.loading = true
    if (!this.isRequest) {
      await this.fetchRequest()
    }
    if (!this.allSamples) {
      await this.sampleFetch()
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          if (this.active) {
            this.setSampleInit(this.active)
          }
          this.loading = false
        })
    } else {
      if (this.active) {
        this.setSampleInit(this.active)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.switchButton{
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-size: 14px;
  line-height: normal;
  margin: 10px 0;
  border: 1px solid var(--Primary-7, #096DD9);
  background: var(--Color-White, #FFF);
  padding: 11px;
  cursor: pointer;
}
.SamplesPage {
  padding: 0 15px;
  
  &__body {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
  }
  
  &__title {
    color: $polarGreen7;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    white-space: break-spaces;
  }
  
  &__subtitle {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $neutral11;
  }
  
  &__instructions {
    cursor: pointer;
    border: 1px solid $neutral5;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 10px;
    margin-top: 12px;
  }
  
  &__instructions-icon {
    color: $primary7;
    font-size: 14px;
    margin-right: 5px;
  }
  
  &__instructions-link {
    color: $primary8;
  }
  
  &__submit {
    margin-top: 11px;
  }
  
  &__check-icon {
    font-size: 28px;
  }
  
  &__arrow {
    font-size: 14px;
  }
  
  &__sample {
    background-color: $neutral2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
  }
  
  &__sample-left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px
  }
  
  &__sample-right {}
  
  &__sample-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  &__sample-done {
    cursor: pointer;
    
    &:deep(.SamplesPage__check-icon) {
      color: $polarGreen7;
    }
    &:deep(.SamplesPage__sample-label) {
      color: $polarGreen7;
    }
    &:deep(.SamplesPage__arrow) {
      color: $primary10;
    }
  }
  
  &__sample-active {
    cursor: pointer;
    
    &:deep(.SamplesPage__check-icon) {
      color: $primary10;
    }
    &:deep(.SamplesPage__sample-label) {
      color: $primary10;
    }
    &:deep(.SamplesPage__arrow) {
      color: $primary10;
    }
  }
  
  &__sample-blocked {
    &:deep(.SamplesPage__check-icon) {
      color: $neutral5;
    }
    &:deep(.SamplesPage__sample-label) {
      color: $neutral5;
    }
    &:deep(.SamplesPage__arrow) {
      color: $neutral5;
    }
  }
  
}
</style>